<template>
  <div class="cart">
    <!-- <form @submit.prevent="SimpanPenjualan"> -->
    <div class="cart-title">Keranjang Belanja Anda</div>
    <hr class="price-line" />
    <div class="cart-area my-3">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th> Id Penulis </vs-th>
            <vs-th> Judul Buku </vs-th>
            <vs-th> Penulis </vs-th>
            <vs-th> QTY </vs-th>
            <vs-th> Sub Total </vs-th>
            <vs-th> Diskon </vs-th>
            <vs-th> Nilai Diskon </vs-th>
            <vs-th> Bagi Hasil </vs-th>
            <vs-th> Bersih </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(item, i) in cart" :data="item">
            <vs-td>{{ item.id_account }}</vs-td>
            <vs-td>
              <div class="row align-items-center">
                <div class="col-md-2 col-lg-2">
                  <img
                    :src="item.file_cover | cover"
                    class="card-img-top product-image"
                  />
                </div>
                <div class="col-md-10 col-lg-10">
                  <div class="cart-product-title">{{ item.judul }}</div>
                  <div class="cart-product-qty">
                    {{ item.harga | currency }}
                  </div>
                </div>
              </div>
            </vs-td>
            <vs-td>{{ item.penulis }}</vs-td>
            <vs-td style="width: 15%">
              <div class="cart-qty-form d-flex">
                <button
                  type="button"
                  class="cart-btn"
                  @click="reduceQty(item.id_file_naskah)"
                >
                  <b-icon icon="dash"></b-icon>
                </button>
                <input
                  type="number"
                  class="form-control cart-qty"
                  :value="item.qty"
                  disabled
                />
                <button
                  type="button"
                  class="cart-btn"
                  @click="addQty(item.id_file_naskah)"
                >
                  <b-icon icon="plus"></b-icon>
                </button>
                <button
                  type="button"
                  class="cart-btn"
                  @click="removeItem(item.id_file_naskah)"
                >
                  <b-icon icon="trash"></b-icon>
                </button>
              </div>
            </vs-td>

            <vs-td style="width: 15%">
              {{ item.total_price_item | currency }}
            </vs-td>

            <vs-td style="width: 15%">
              <div class="cart-qty-form d-flex">
                <input
                  type="number"
                  class="form-control cart-qty"
                  :value="item.discount"
                  @input="e => setDiscount(item.id_file_naskah, e)"
                />
              </div>
            </vs-td>

            <vs-td id="diskon" style="width: 10%">
              {{(item.total_price_item * (item.discount / 100)) | currency}}
            </vs-td>
            <vs-td id="bagi-hasil" style="width: 10%">
              <span v-if="item.discount != 40">
                {{ ((item.total_price_item - (item.total_price_item * (item.discount / 100))) * 0.15) | currency }}
              </span>
              <span v-else>{{ "0" | currency }}</span>
            </vs-td>

            <vs-td id="bersih" style="width: 10%">
              <span v-if="item.discount != 40">
                {{(item.total_price_item - (item.total_price_item * (item.discount / 100)) - ((item.total_price_item - (item.total_price_item * (item.discount / 100))) * 0.15)) | currency}}
              </span>

              <span v-else-if="item.discount == 40">{{(item.total_price_item - (item.total_price_item * (item.discount / 100))) | currency}}</span>

              <span v-else-if="item.discount == 0">{{(item.total_price_item - item.total_price_item * 0.15) | currency}}</span>
            </vs-td>
          </vs-tr>
          <vs-tr class="fw-bold">
            <vs-td colspan="4" class="text-end">Sub Total Barang</vs-td>
            <vs-td colspan="4">{{ totalPrice | currency }}</vs-td>
          </vs-tr>
          <vs-tr class="fw-bold text-danger">
            <vs-td colspan="4" class="text-end">Total Diskon</vs-td>
            <vs-td colspan="4">{{ totalDiskon | currency }}</vs-td>
          </vs-tr>
          <vs-tr class="fw-bold">
            <vs-td colspan="2" class="text-end">Jasa Pengiriman</vs-td>
            <vs-td colspan="2" class="text-end">
              <select
                class="form-control text-end"
                aria-label="Default select example"
                v-model="ongkir.service"
              >
                <option selected disabled>Jasa Pengiriman</option>
                <option value="JNE OKE">JNE OKE</option>
                <option value="JNE REG">JNE REG</option>
                <option value="JNE YES">JNE YES</option>
                <option value="JNE CTC">JNE CTC</option>
                <option value="JNE JTR">JNE JTR</option>
                <option value="SICEPAT REG">SICEPAT REG</option>
                <option value="SICEPAT BEST">SICEPAT BEST</option>
                <option value="SICEPAT HALU">SICEPAT HALU</option>
                <option value="SICEPAT GOKIL">SICEPAT GOKIL</option>
                <option value="SICEPAT SI UNTUNG">SICEPAT SI UNTUNG</option>
                <option value="JNT REGULER">JNT REGULER</option>
                <option value="ANTER AJA REGULER">ANTER AJA REGULER</option>
                <option value="ANTER AJA NEXT DAY">ANTER AJA NEXT DAY</option>
                <option value="ANTER AJA ECONOMY">ANTER AJA ECONOMY</option>
                <option value="SHOPEE EXPRESS">SHOPEE EXPRESS</option>
                <option value="GRAB">Grab</option>
                <option value="GOJEK">Gojek</option>
              </select>
            </vs-td>
            <vs-td>
              <input
                type="number"
                class="form-control"
                v-model.number="ongkir.amount"
              />
            </vs-td>
          </vs-tr>
          <vs-tr class="fw-bold">
            <vs-td colspan="4" class="text-end">Total Bayar</vs-td>
            <vs-td colspan="4">{{ totalBayar | currency }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-6">
        <h5>Detail Pengiriman</h5>
        <hr class="price-line" />

        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Nama Penerima</label
            >
            <input
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              v-model="form.penerima"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Nama Kontak</label
            >
            <input
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
              v-model="form.kontak"
            />
          </div>
          <div class="col-md-12 mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Alamat Lengkap</label
            >
            <textarea class="form-control" rows="6" v-model="form.alamat"></textarea>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label class="form-check-label" for="flexCheckDefault">
            Platform Pesanan
          </label>
          <select class="form-control" v-model="form.penjualan">
            <option selected disabled>Pilih Platform</option>
            <option value="Store Guepedia">Store Guepedia</option>
            <option value="Guepedia.com">Guepedia.com</option>
            <option value="Bukalapak">Bukalapak</option>
            <option value="Tokopedia">Tokopedia</option>
            <option value="Shopee">Shopee</option>
            <option value="Lazada">Lazada</option>
            <option value="Whatsapp">Whatsapp</option>
            <option value="Instagram">Instagram</option>
            <option value="Retur">Retur</option>
            <option value="FREE ISBN">FREE ISBN</option>
            <option value="FREE REVIEW">FREE REVIEW</option>
          </select>
        </div>
        <div class="mb-3">
          <b-form-checkbox
            v-model="form.cashless"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
          <label class="form-check-label ms-3" for="flexCheckDefault">
            Cashless
          </label>
          </b-form-checkbox>
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Kode Booking</label
          >
          <input
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
            v-model="form.booking"
          />
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Catatan Pengiriman</label
          >
          <textarea class="form-control" v-model="form.catatan"></textarea>
        </div>

        <div class="center">
          <vs-button type="submit" success block class="text-uppercase" @click="SimpanPenjualan">
            Simpan Pesanan
          </vs-button>
        </div>
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["cart"]),
  },
  data() {
    return {
      ongkir: {
        service: "",
        amount: 0,
      },
      form: {
        penerima: "",
        kontak: "",
        alamat: "",
        note: "",
        penjualan: "",
        booking: "",
        cashless: "",
        catatan: ""
      },
    };
  },
  methods: {
    ...mapActions(["addQty", "reduceQty", "removeItem", "setProductDiscount", "clearState"]),
    setDiscount(id, e) {
      this.setProductDiscount({id, value: e.target.value});
      console.log(this.cart);
    },
    
    SimpanPenjualan() {
      var cart = JSON.stringify(this.cart);
      var totalDiscount = this.cart.reduce((a, b) => a + b.discount, 0);

      var formData = new FormData();

      formData.append("cart", cart);
      formData.append("totalDiskon", this.totalDiskon);
      formData.append("penerima", this.form.penerima);
      formData.append("kontak", this.form.kontak);
      formData.append("alamat", this.form.alamat);
      formData.append("totalBayar", this.totalBayar);
      formData.append("service", this.ongkir.service);
      formData.append("ongkir", this.ongkir.amount);
      formData.append("diskon_total", totalDiscount);
      formData.append("totalBelanja", this.totalPrice);
      formData.append("penjualan", this.form.penjualan);
      formData.append("kode_booking", this.form.booking);
      formData.append("cashless", this.form.cashless);
      formData.append("catatan", this.form.catatan);

      axios
        .post("simpanPenjualan", formData, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
          },
        })
        .then((response) => {
          this.openNotification(
            6000,
            "top-right",
            "success",
            "Success",
            response.data.message
          );
          this.clearState();
        })
        .catch((error) => {
          this.openNotification(
            6000,
            "top-right",
            "danger",
            "Autentikasi Gagal",
            error.response.data.message
          );
        });
      // localStorage.setItem('orderDetail', response.data)
    },
  },
  watch: {
    cart: {
      handler(cart) {
        console.log(cart);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["cart"]),
    totalPrice() {
      return this.cart.reduce((a, b) => a + b.qty * b.harga, 0);
    },

    totalDiskon() {
      return this.cart.reduce((a, b) => a + b.qty * b.harga * b.discount / 100, 0);
    },

    totalBayar() {
      return (
        this.totalPrice +
        this.ongkir.amount -
        this.totalDiskon
      );
    },
  },
};
</script>
